import React from 'react'
import '../styles/Contact.scss'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

function Contact() {
  return (
    <div className='contact'>
      <Navbar />
        
      <div className='contactHead'>
        Contact Us
      </div>

      <div className='contactWrapper'>
          
        <div className='info'>

          <div className='head'>Contact Information</div>
          <h4>WOW Capital Pvt. Ltd.</h4>
          <p>Flat No 308 , Block A-22, Sanskruti Township, Pocharam, Hyderabad - 500088</p>
          <p>Mobile : +91 9985474741 / 8790683557</p>
          <p>Mail : customercare@wow-capital.com</p>
          <p>Inquiries : info@wow-capital.com</p>
        
        </div>

        <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d951.602010546843!2d78.64763613173828!3d17.440173499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9dfdab6d927f%3A0xb7d499634cc2289b!2sSanskruti%20Township%20Bus%20Stop!5e0!3m2!1sen!2sin!4v1694865515979!5m2!1sen!2sin" title='Map' width="400" height="360" style={{border:0}} loading="lazy" />
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default Contact