import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/Navbar.scss'

const logo = require('../images/logo.png')

function CollapsibleExample(props) {

  return (
    <Navbar fixed='top' collapseOnSelect expand="lg" data-bs-theme="light" className="bg-body-tertiary">
      <Container>

        <Navbar.Brand href="/">
            <img src={logo} alt='WOW Capital Logo' /> &ensp; WOW CAPITAL
        </Navbar.Brand>

      </Container>
    </Navbar>
  );
}

export default CollapsibleExample