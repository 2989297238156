import React from 'react'
import '../styles/CSR.scss'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

function CSR() {
  return (
    <div className='csr'>
      <Navbar />
        
      <div className='csrHead'>
        Corporate Social Responsibility
        <p>The happiness you derive from serving others, is the happiness of the highest form</p>
      </div>

      <div className='csrWrapper'>
          
        <div className='info'>

          <div className='head'>A Good Deed Everyday</div>
          <h5>This will be the driving Principle behind our CSR division</h5>
          <p>Irrespective of the size of the deed, it is important to do a good deed everyday.</p>
          <p>The good deed can be anything such as - watering a tree/plant, feeding birds/animals, helping the needy, feeding the poor, donating blood, donating clothes and books, sponsoring education, helping the aged, extending monetary help for medical treatment, housing to poor, providing water supply and many more.</p>

        </div>

        <div className='map'>
          <div className='csrImg' />
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default CSR