import React from 'react'
import '../styles/NotFound.scss'
import { useNavigate } from 'react-router-dom'
import NotFoundNav from '../components/NotFoundNav'

function NotFound() {
  
  const navigate = useNavigate()

  return (
    <div>
    <NotFoundNav />

    <div className='notFound'>
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <div 
          className='notFoundBtn'
          onClick={() => navigate('/')}
        > Go to Home </div>
    </div>
    
    </div>
  )
}

export default NotFound