import React from 'react'
import '../styles/Vision.scss'

function Vision() {

  const info = [
    {
      title: 'Vision',
      content: 'To become the largest investment company on the planet'
    }, {
      title: 'Principle',
      content: 'We should grow along with others'
    }, {
      title: 'Mission',
      content: 'To enable our investors achieve the state of Financial Freedom'
    }
  ]

  return (
    <div className='visionWrapper'>
      {
        info.map((i,n) =>
          <div key={n} className='vision'>
            <h5> {i.title} </h5>
            <p> {i.content} </p>
          </div>
        )
      }
      
    </div> 
  )
}

export default Vision