import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
// import NavDropdown from 'react-bootstrap/NavDropdown'
import {AiOutlineStock} from 'react-icons/ai'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/Navbar.scss'
import { useNavigate } from 'react-router-dom'

const logo = require('../images/logo.png')

function CollapsibleExample(props) {

  const navigate = useNavigate();

  return (
    <Navbar fixed='top' collapseOnSelect expand="lg" data-bs-theme="light" className="bg-body-tertiary">
      <Container>

        <Navbar.Brand href="/">
            <img src={logo} alt='WOW Capital Logo' /> &ensp; WOW CAPITAL
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='ms-auto'>

            {
              props.scrollHome ? 
              <>
                <Nav.Link href="#home" className='me-1'>Home</Nav.Link>
                <Nav.Link href="#about" className='me-1'>About</Nav.Link>    
                <Nav.Link href="#whyus" className='me-1'>Why us?</Nav.Link>
              </>
              :
              <>
                <Nav.Link href="/#home" className='me-1'>Home</Nav.Link>
                <Nav.Link onClick={() => navigate('/')} href='/#about' className='me-1'>About</Nav.Link>    
                <Nav.Link onClick={() => navigate('/')} href="/#whyus" className='me-1'>Why us?</Nav.Link>
              </>
            }

            <Nav.Link href='/csr' className='me-2'>CSR</Nav.Link>
            <Nav.Link href='/contact' className='me-2'>Contact</Nav.Link>
            <Nav.Link href='/invest' className='nav-custom-btn'>
                <AiOutlineStock className='mt-1 me-1' size={24} /> Invest Now
            </Nav.Link>

          </Nav>
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample