import React from 'react'
import '../styles/InvestNow.scss'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import { TbPigMoney } from 'react-icons/tb'
import { MdAccountBalance } from 'react-icons/md'
import { FaPeopleGroup } from 'react-icons/fa6'

function InvestNow() {
  return (
    <div className='invest'>
        <Navbar />

        <div className='investWrapper'>

          <h4>Invest Now</h4>
          <ul>
            <li><p>There is no cap on the amount one can invest in our company. Investment can be lumpsum or a regular amount every month. We tell our investors not to cross their comfort zone and invest. We ask them to invest only that amount which they are comfortable with.</p></li>
            <li><p>We shall allocate our company's shares in accordance with your Investment. The face value of our share is Rs.10. For example, if you invest Rs.10000, you will be allocated - 10000/10 = 1000 shares.</p></li>
            <li><p>Many people think that they need to invest a large sum of money at once or a reasonable amount of 10000 and above on a monthly basis. That is not the case. Investment can be as low as Rs.100 per day.</p></li>
          </ul>

          <div className='vdeck'>

            <div className='vcard'>
              <TbPigMoney size={50} color='#89CFF0' />
              <h4>Minimum Investment</h4>
              <p>One can also invest Rs.100 per day = Rs.3000 per month</p>
            </div>

            <div className='vcard'>
              <FaPeopleGroup size={50} color='#89CFF0' />
              <h4>Our Role</h4>
              <p>What is more important is that we maintain the discipline of investing every month than the size of the amount one invests</p>
            </div>

            <div className='vcard'>
              <MdAccountBalance size={45} color='#89CFF0' />
              <h4>Account Details</h4>
              <p>WOW CAPITAL PVT LTD <br /> Account number - 0815360000002091 <br /> IFSC Code - DBSS0IN0814 <br /> Branch - MVP Colony, Visakhapatnam</p>
            </div>

          </div>

        </div>

        <Footer />
    </div>
  )
}

export default InvestNow