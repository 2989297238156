import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homescreen from './screens/Homescreen';
import Contact from "./screens/Contact";
import InvestNow from "./screens/InvestNow";
import CSR from "./screens/CSR";
import NotFound from "./screens/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>

        <Route path='/' element={<Homescreen />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/invest' element={<InvestNow />} />
        <Route path='/csr' element={<CSR />} />
        <Route path='*' element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
