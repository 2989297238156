import React from 'react'
import '../styles/Homescreen.scss'

import Navbar from '../components/Navbar'
import About from '../components/About'
import Vision from '../components/Vision'
import WhyUs from '../components/WhyUs'
import Footer from '../components/Footer'

function Homescreen() {

  return (
    <div className='homescreen'>

        <Navbar scrollHome = {true} />

        <div id='home' className='homeWrapper'>
          <div className='homeImage' />
        </div>

        <div 
          id='about' 
          style={{ top: '600px', position: 'absolute' }}  
        />

        <About />
        <Vision />
        <WhyUs />
        <Footer />
        
    </div>
  )
}

export default Homescreen