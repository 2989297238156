import React from 'react'
import '../styles/About.scss'

function About() {
  return (
    <div 
      id='whyus'
      className='about'
      style={{ padding: '70px 30px' }}
      >

        <h4>Why Us?</h4>
        <p>As individuals, we are constrained in our investments, often limited to a few companies and common assets like stocks and gold. As a collective, our possibilities expand diversifying across various asset classes including real estate thereby enhancing our risk tolerance, seize opportunities and pursue superior returns.</p>
        
    </div>    
  )
}

export default About