import React from 'react'
import '../styles/Footer.scss'

function Footer() {
  return (
    <div className='footer'>
        <p>© 2023 - WOW Capital Pvt. Ltd.</p>
    </div>
  )
}

export default Footer