import React from 'react'
import '../styles/About.scss'

function About() {
  return (
    <div 
      className='about'
      >
        <h4>About</h4>
        <p>We believe in adding value not just to ourselves but also to our customers and our partners or community at large with whom we associate. We are an investment company. We focus on Investing in equities and properties. Trust and credibility are the pillars on which we intend to build our company into an investment company of global scale.</p>
    </div>    
  )
}

export default About